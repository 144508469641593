<template>
  <div>
    <section class="s-testing">
      <div class="container">
        <div class="hero-title">Оценка уровня ЭИ зрелости компании</div>
        <div class="hero-text" v-if="currentQuestionNumber == 0">
          Пройдите мини-опросник и определите эмоционально-интеллектуальную зрелость компании,
          ведь продуктивная ЭИ-среда позволяет создать атмосферу для <i>вовлечения команды,
          эффективности управленцев, слаженной коммуникации</i>. А это повышение <b>работоспособности,
          стрессоустойчивости и жизнестойкости</b> сотрудников.
          <br /><br />
          Для этого внимательно прочтите предложенные кейсы.
          Оцените вероятность, насколько данная ситуация соответствует обстановке в вашей компании по 10-балльной шкале,
          где 10 - не соответствует совсем, а <b>1 - максимально соответствует</b>.
          И получите диаграмму ЭИ-зрелости вашей компании на данном этапе развития.
        </div>
        <div class="testing-area">
          <div class="testing-area__questions">
            <div v-if="currentQuestion" class="testing-area__questions-content">
              <p class="testing-area__task">Задача</p>
              <p class="testing-area__title">{{ currentQuestion.question }}</p>
              <img :src="currentQuestion.full_image" alt="" />

              <div class="testing-area__text">
                {{ currentQuestion.description }}
                <p>
                  Оцените по 10-балльной шкале, насколько данная ситуация
                  соответствует обстановке в вашей компании.
                </p>
              </div>
            </div>
            <div class="testing-area__actions">
              <div class="btn-label btn-label-left">
                Максимально соответствует
              </div>
              <button
                class="btn btn-o btn-sm"
                @click="activeValue = index"
                :class="{ active: activeValue == index }"
                v-for="index in 10"
                :key="index"
              >
                {{ index }}
              </button>
              <div class="btn-label btn-label-right">
                Не соответствует совсем
              </div>
            </div>
            <div
              v-if="activeValue !== 0"
              class="testing-area__next btn btn-accent"
              @click="next"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="currentQuestion && activeValue" class="testing-area__answers">
            <p class="testing-area__task">Разбор кейса</p>
            <div class="testing-area__error">
              <img src="@/assets/images/icons/error.png" alt="error" />
              <div>
                <p class="testing-area__error-title">
                  {{ currentQuestion.inaction_title }}
                </p>
                <p class="testing-area__answer-message">
                  {{ currentQuestion.inaction }}
                </p>
              </div>
            </div>
            <div class="testing-area__success">
              <img src="@/assets/images/icons/success.png" alt="success" />
              <div>
                <p class="testing-area__success-title">
                  {{ currentQuestion.assessment_title }}
                </p>
                <p class="testing-area__answer-message">
                  {{ currentQuestion.assessment }}
                </p>
              </div>
            </div>
            <div
              v-if="activeValue"
              class="testing-area__next-mobile btn btn-accent"
              @click="next"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  name: "testing-page",
  setup() {
    const router = useRouter();

    const activeValue = ref(0),
      answers = {};

    let questions = ref([]),
      currentQuestion = ref({}),
      currentQuestionNumber = ref(0),
      message = ref("Далее");

    onBeforeMount(async () => {
      if (localStorage.getItem("finalScore")) {
        router.push("/testing-result");
      }
      else{
        await getTests();        
      }
    });

    let getTests = async () => {
      await axios
        .get(process.env.VUE_APP_API_URL + "/tests/list")
        .then(({ data }) => {
          questions.value = data.data;
          currentQuestion.value = questions.value[0];
        })
        .catch((error) => console.log("error", error));
    };

    let next = () => {
      document.querySelector(".testing-area__questions").scrollIntoView()
      currentQuestionNumber.value = questions.value.indexOf(currentQuestion.value) + 1;

      answers[currentQuestion.value.result_text] = activeValue.value;

      if (currentQuestionNumber.value == questions.value.length) {
        localStorage.setItem("finalScore", JSON.stringify(answers));
        router.push("/testing-result");
      }
      if (currentQuestionNumber.value == questions.value.length - 1) {
        message.value = "Закончить тестирование";
      }
      currentQuestion.value = questions.value[currentQuestionNumber.value];
      activeValue.value = 0;
    };

    return {
      questions,
      currentQuestion,
      getTests,
      next,
      currentQuestionNumber,
      message,

      activeValue,
      answers,
    };
  },
};
</script>

<style lang="scss" scoped>
.s-testing {
  padding: 150px 0 60px;

  .hero-title {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .hero-text {
    font-size: 22px;
  }

  @media (max-width: 767px) {
    padding: 70px 0 60px;
  }
}

.testing-area {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  &__questions,
  &__answers {
    flex: 0 1 47%;
  }

  &__questions{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__questions-content {
    font-size: 16px;
    img {
      max-width: 100%;
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      border: 1px solid #f7f7f7;
      border-radius: 20px;
    }
  }

  &__title, &__task {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  &__text {
    font-size: 19px;
    line-height: 27px;

    p {
      margin-top: 40px;
      font-weight: 400;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding-bottom: 43px;
    justify-content: space-between;
    position: relative;

    .btn-label {
      position: absolute;
      bottom: 0px;
      max-width: 140px;
      font-size: 13px;

      &-left {
        left: 0;
      }

      &-right {
        right: 0;
        text-align: right;
      }
    }

    button {
      padding: 0;
      height: 38px;
      width: 38px;
      justify-content: center;
      line-height: 1.4em;
      margin-left: 0;
      margin-right: 6px;
    }
  }

  &__error,
  &__success {
    padding: 30px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 5%);
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    img {
      margin-right: 10px;
    }
  }

  &__error-title,
  &__success-title {
    font-weight: 400;
    margin: 0;
  }

  &__error-title {
    color: #bb0000;
  }
  &__success-title {
    color: #5acc2e;
  }

  &__answer-message {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
  }

  &__next-mobile {
    display: none;
  }

  @media (max-width: 996px) {
    flex-direction: column;

    &__questions,
    &__answers {
      flex: 1;
    }

    &__next-mobile {
      display: block;
    }
    &__next {
      display: none;
    }

    &__actions {
      button {
        margin: 0 10px 10px 0;
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>